import type {HydratedT} from '@/components/shared/Page/I18N';

type NumberOptions = {
  notation: 'compact' | 'standard';
  compactDisplay: 'short' | 'long';
  style: 'decimal' | 'percent' | 'currency';
  minimumSignificantDigits?: number;
  maximumFractionDigits?: number;
  currency?: string;
  currencyDisplay?: 'symbol' | 'narrowSymbol' | 'code' | 'name';
};

const defaultNumberOptions: NumberOptions = {
  notation: 'compact',
  compactDisplay: 'short',
  style: 'decimal',
  currency: 'USD',
  currencyDisplay: 'narrowSymbol',
};

type InterpolationOptions = {
  line: string;
  placeholder: string;
};

const defaultInterpolationOptions: InterpolationOptions = {
  line: 'value',
  placeholder: 'raw',
};

export const number = (
  t: HydratedT,
  prefix: string,
  options: Partial<InterpolationOptions & NumberOptions> = {
    ...defaultInterpolationOptions,
    ...defaultNumberOptions,
  },
) => {
  const mergedOptions = {
    ...defaultInterpolationOptions,
    ...defaultNumberOptions,
    ...options,
  };
  const {line, placeholder, ...rest} = mergedOptions;

  return t(`${prefix}.${line}`, {
    [placeholder]: t(`${prefix}.${placeholder}`),
    ...rest,
  });
};

export const currency = (
  t: HydratedT,
  prefix: string,
  options?: Partial<InterpolationOptions & NumberOptions>,
) => number(t, prefix, {style: 'currency', ...options});

// mostly taken from here and modified to match B3 pathPreifx:
// https://github.com/Shopify/mozart/blob/main/config/initializers/locale.rb#L104
export const fallbacks = {
  'da-DK': 'dk',
  'de-DE': 'de',
  'de-AT': 'de',
  'de-CH': 'de',
  'de-IT': 'de',
  'es-AR': 'es',
  'es-BO': 'es',
  'es-BR': 'es',
  'es-CL': 'es',
  'es-CR': 'es',
  'es-DO': 'es',
  'es-EC': 'es',
  'es-GT': 'es',
  'es-HN': 'es',
  'es-PA': 'es',
  'es-PE': 'es',
  'es-PR': 'es',
  'es-PY': 'es',
  'es-SV': 'es',
  'es-US': 'es',
  'es-UY': 'es',
  'es-VE': 'es',
  'fr-BF': 'fr',
  'fr-BJ': 'fr',
  'fr-CD': 'fr',
  'fr-CG': 'fr',
  'fr-CH': 'fr',
  'fr-CI': 'fr',
  'fr-CM': 'fr',
  'fr-DZ': 'fr',
  'fr-FR': 'fr',
  'fr-GA': 'fr',
  'fr-GF': 'fr',
  'fr-GP': 'fr',
  'fr-HT': 'fr',
  'fr-LU': 'fr',
  'fr-MA': 'fr',
  'fr-MG': 'fr',
  'fr-MQ': 'fr',
  'fr-RE': 'fr',
  'fr-SN': 'fr',
  'fr-TN': 'fr',
  'fr-NC': 'fr',
  'it-CH': 'it',
  'it-IT': 'it',
  'ja-JP': 'jp',
  'ko-KR': 'kr',
  'pt-PT': 'br',
  pt: 'br',
  'nl-AW': 'nl',
  'nl-NL': 'nl',
  'nl-SR': 'nl',
  'sv-FI': 'se',
  'sv-SE': 'se',
  'vi-VN': 'vn',
};
